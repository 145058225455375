import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';

import { BibliaInfoChapterModel } from '../../../../../services/biblia-info/biblia-info.model';
import { BibleAvailableTranslationsComponent } from '../available-translations/bible-available-translations.component';
import { IconButtonComponent } from '../../../../../components/icon-button/icon-button.component';
import { MediaDirectivesModule } from '../../../../../directives/media/media-directives.module';
import { SharedPipesModule } from '../../../../../pipes/shared-pipes.module';
import { BIBLE_DESC } from '../../../../../services/biblia-info/bible-desc';

export interface TranslationInfoData {
  chapterItem: BibliaInfoChapterModel;
}

@Component({
  templateUrl: './translation-info-dialog.component.html',
  styleUrls: ['./translation-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BibleAvailableTranslationsComponent,
    CommonModule,
    IconButtonComponent,
    MatDialogModule,
    MatIconModule,
    MediaDirectivesModule,
    SharedPipesModule,
  ],
})
export class TranslationInfoDialogComponent {
  protected readonly descriptions = BIBLE_DESC;

  constructor(@Inject(MAT_DIALOG_DATA) public data: TranslationInfoData,
              public dialogRef: MatDialogRef<TranslationInfoDialogComponent, void>,) {
  }
}
