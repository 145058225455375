import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BibleCode, bibleCodes } from './bible-codes';
import { BibliaInfoBook } from '../biblia-info/biblia-info-book';
import { BibliaInfoChapterModel } from '../biblia-info/biblia-info.model';
import { BibliaInfoCode } from '../biblia-info/biblia-info-code';
import { BibliaInfoService } from '../biblia-info/biblia-info.service';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class BibleService {
  private readonly url = `${environment.api_endpoint}/bible`;

  private readonly bibliaInfoService = inject(BibliaInfoService);
  private readonly httpClient = inject(HttpClient);

  getChapter(bible: BibleCode | BibliaInfoCode, book: BibliaInfoBook, chapter: number): Observable<BibliaInfoChapterModel> {
    return bibleCodes.has(bible as BibleCode)
      ? this.httpClient.get<any>(`${this.url}/${bible}/${book}/${chapter}`)
        .pipe(catchError(() => this.bibliaInfoService.getChapter(bible as BibliaInfoCode, book, chapter)))
      : this.bibliaInfoService.getChapter(bible as BibliaInfoCode, book, chapter);
  }
}
