<div mat-dialog-title>
  <h1>
    <mat-icon svgIcon="icons:settings"></mat-icon>
    Ustawienia
  </h1>

  <app-icon-button label="zamknij okno" icon="close" class="icon-large close" (click$)="dialogRef.close()"></app-icon-button>
</div>

<div mat-dialog-content>
  <div class="actions">
    <div *onlyDesktop class="action">
      <app-icon-button label="pełny ekran" icon="screen" altHover="true" [active]="_fullScreen" (click$)="fullScreen = !_fullScreen"></app-icon-button>
      Pełny ekran
    </div>

    <div class="action">
      <app-icon-button label="wyświetl numery Stronga" icon="numbers" altHover="true" [active]="_showStrong" (click$)="showStrong = !_showStrong"></app-icon-button>
      Wyświetl numery Stronga
    </div>

    <div class="action">
      <app-icon-button label="wyświetl słowa greckie" icon="alpha-omega" altHover="true" [active]="_showGreek" (click$)="showGreek = !_showGreek"></app-icon-button>
      Wyświetl słowa greckie
    </div>

    <div class="action">
      <app-icon-button label="wyświetl transliterację słów" icon="a-z" altHover="true" [active]="_showTransliteration" (click$)="showTransliteration = !_showTransliteration"></app-icon-button>
      Wyświetl transliterację słów
    </div>

    <div class="action" *onlyPortraitLandscape>
      <app-icon-button label="widok kompaktowy" icon="format-size" altHover="true" [active]="_compactMode" (click$)="compactMode = !_compactMode"></app-icon-button>
      Widok kompaktowy
    </div>
  </div>
</div>
