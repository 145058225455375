import { ChangeDetectionStrategy, Component, HostBinding, HostListener, inject, input, OnInit, output, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

import {
  BibleQueryData,
  BibleQueryResult,
  BibleSearchQueryDialogComponent
} from './bible-search-query-dialog/bible-search-query-dialog.component';
import { BibleQueryService } from '../../../../services/bible-query.service';
import { MenuService } from '../../../../services/menu.service';
import { SubComponent } from '../../../../components/utils/sub/sub.component';

@Component({
  selector: 'app-bible-search-query',
  templateUrl: './bible-search-query.component.html',
  styleUrls: ['./bible-search-query.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BibleSearchQueryDialogComponent,
    MatIcon,
  ],
  host: {
    '[class.sidenav-opened]': 'sidenavOpened()',
    '[class.fixed]': 'fixed()',
  }
})
export class BibleSearchQueryComponent extends SubComponent implements OnInit {
  protected readonly fixed = input<boolean>(true);
  protected readonly onlyNT = input<boolean>();

  protected readonly query$ = output<BibleQueryResult>();

  protected readonly disable = signal<boolean>(false);
  protected readonly sidenavOpened = signal<boolean>(false);

  private readonly bibleQueryService = inject(BibleQueryService);
  private readonly dialog = inject(MatDialog);
  private readonly menuService = inject(MenuService);

  private opened: boolean;

  @HostListener('window:keydown.shift.f')
  handleKeyboardEvent() {
    this.openSearchBox();
  }

  ngOnInit(): void {
    this.observeMenuToggle();
    this.observeSearchInput();
  }

  private observeMenuToggle(): void {
    this.subscription.add(
      this.menuService.toggle$.subscribe(value => this.sidenavOpened.set(value))
    );
  }

  private observeSearchInput(): void {
    this.subscription.add(
      this.bibleQueryService.disableQuery$.subscribe(disable => this.disable.set(disable))
    );
  }

  protected openSearchBox(): void {
    if (this.opened || this.disable()) {
      return;
    }
    this.opened = true;
    this.subscription.add(
      this.dialog.open<BibleSearchQueryDialogComponent, BibleQueryData>(BibleSearchQueryDialogComponent, { data: { onlyNT: this.onlyNT() }})
        .afterClosed().subscribe(result => {
          this.opened = false;
          if (result) {
            this.query$.emit(result);
          }
        })
    );
  }
}
