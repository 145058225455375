import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding, inject,
  Input,
  OnChanges,
  OnInit,
  output,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { HttpErrorResponse } from '@angular/common/http';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';

import { catchError, debounceTime, delay, filter, tap } from 'rxjs/operators';
import { fromEvent, of } from 'rxjs';

import { BibleComponent } from '../bible.component';
import {
  BibleContentBottomSheetComponent,
  BibleContentBottomSheetResult,
  BibleContentBottomSheetResultType
} from './bottom-sheet/bible-content-bottom-sheet.component';
import { BibleQueryResult } from '../bible-search-query/bible-search-query-dialog/bible-search-query-dialog.component';
import { BibliaInfoBook } from '../../../../services/biblia-info/biblia-info-book';
import { BibliaInfoChapterModel } from '../../../../services/biblia-info/biblia-info.model';
import { BibliaInfoCode } from '../../../../services/biblia-info/biblia-info-code';
import { BreakpointsService } from '../../../../services/breakpoints.service';
import { ChapterChangeResult } from '../chapter-button/chapter-button.component';
import { ConcordanceDialogComponent } from '../../strong/concordance/dialog/concordance-dialog.component';
import { CopyVersesDialogData, CopyVersesDialogComponent, CopyVersesDialogResult } from './copy-verses-dialog/copy-verses-dialog.component';
import { FavouriteChapter } from '../../../../services/left-panel/favourite-chapter.model';
import { LeftPanelService } from '../../../../services/left-panel/left-panel.service';
import { SelectNumberDialogComponent } from '../../strong/select-number-dialog/select-number-dialog.component';
import { SnackbarService } from '../../../../components/snackbar/snackbar.service';
import { SnackBarType } from '../../../../components/snackbar/snackbar-type.enum';
import { StateService } from '../../../../services/state/state.service';
import { Strong, VerseUbg } from '../../../../services/strong/strong.model';
import { StrongService } from '../../../../services/strong/strong.service';
import { SubComponent } from '../../../../components/utils/sub/sub.component';
import { TranslationInfoData, TranslationInfoDialogComponent } from './translation-info-dialog/translation-info-dialog.component';
import { MenuService } from '../../../../services/menu.service';

@Component({
  selector: 'app-bible-content',
  templateUrl: './bible-content.component.html',
  styleUrls: ['./bible-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BibleContentComponent extends SubComponent implements OnInit, OnChanges, AfterViewInit {
  @Output() protected readonly chapterChange$ = new EventEmitter<ChapterChangeResult>();
  @Output() protected readonly compareVerse$ = new EventEmitter<FavouriteChapter>();
  @Output() protected readonly favouriteChapterChange$ = new EventEmitter<boolean>();
  @Output() protected readonly openToolsPanel$ = new EventEmitter<void>();
  @Output() protected readonly openBibleBookDialog$ = new EventEmitter<void>();
  @Output() protected readonly openSelectTranslationsDialog$ = new EventEmitter<void>();
  @Output() protected readonly changeFontSize$ = new EventEmitter<number>();
  @Output() protected readonly fullScreen$ = new EventEmitter<boolean>();

  protected readonly bibleSearchQuery$ = output<BibleQueryResult>();

  @Input({ required: true }) chapterItem: BibliaInfoChapterModel;
  @Input({ required: true }) compareItems: Map<BibliaInfoCode, BibliaInfoChapterModel>;
  @Input({ required: true }) description: string;
  @Input() isDialogLoading: boolean;
  @Input({ required: true }) isFavouriteChapter: boolean;
  @Input({ required: true }) hidePrevButton = false;
  @Input({ required: true }) hideNextButton = false;
  @Input({ required: true }) fontSize: number;
  @Input({ required: true }) maxCompareColumns: number;
  @Input({ required: true }) versesInNewLine: boolean;
  @Input({ required: true }) @HostBinding('class.loading') isLoading: boolean;
  @Input({ required: true }) @HostBinding('class.full-screen') fullScreen = false;
  @Input({ required: true }) @HostBinding('class.ubg') versesUbg: VerseUbg[];

  @HostBinding('class.two-columns') private twoColumns = false;

  private readonly bottomSheet = inject(MatBottomSheet);
  private readonly breakpointsService = inject(BreakpointsService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly clipboard = inject(Clipboard);
  private readonly dialog = inject(MatDialog);
  private readonly elementRef = inject(ElementRef<HTMLDivElement>);
  private readonly leftPanelService = inject(LeftPanelService);
  private readonly menuService = inject(MenuService);
  private readonly snackbarService = inject(SnackbarService);
  private readonly stateService = inject(StateService);
  private readonly strongService = inject(StrongService);

  protected activeVerseIndex: string;
  protected displayCompareItems: Map<BibliaInfoCode, BibliaInfoChapterModel>;

  private actionsAbsolutePositionInPx: number;
  protected actionsAbsolute = false;
  protected hideActions = false;

  private navigationOpened = signal<boolean>(false);

  protected get firstCompareItem(): [BibliaInfoCode, BibliaInfoChapterModel] {
    return this.compareItems?.entries().next().value;
  }

  private static readonly blacklistBlueLetterBooks = [
    BibliaInfoBook.KSIEGA_TOBIASZA,
    BibliaInfoBook.KSIEGA_JUDYTY,
    BibliaInfoBook.KSIEGA_MACHABEJSKA_1,
    BibliaInfoBook.KSIEGA_MACHABEJSKA_2,
    BibliaInfoBook.KSIEGA_MADROSCI,
    BibliaInfoBook.MADROSC_SYRACHA,
    BibliaInfoBook.KSIEGA_BARUCHA,
  ];

  ngOnInit(): void {
    this.fontSize = this.breakpointsService.isDesktop ? 2 : this.stateService.bibleFontSize;
    this.displayCompareItems = new Map(this.compareItems);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.compareItems) {
      this.twoColumns = this.compareItems?.size > 0;
      if (this.compareItems?.size > 0) {
        this.displayCompareItems = new Map(this.compareItems);
      }
    }
    if (changes.chapterItem) {
      if (!this.breakpointsService.isDesktop) {
        return;
      }
      const chapterItem: BibliaInfoChapterModel = changes.chapterItem.currentValue;
      const prevItem: BibliaInfoChapterModel = changes.chapterItem.previousValue;
      if (!prevItem || chapterItem && (chapterItem.chapter !== prevItem.chapter
        || chapterItem.book.abbreviation !== prevItem.book.abbreviation
        || chapterItem.bible.abbreviation !== prevItem.bible.abbreviation)) {
        setTimeout(() => {
          this.updateActionsAbsoluteBreakpoint();
          this.cdr.markForCheck();
        }, 600);
      }
    }
  }

  ngAfterViewInit(): void {
    if (!this.breakpointsService.isDesktop) {
      return;
    }
    setTimeout(() => this.updateActionsAbsoluteBreakpoint(), 1000);
    this.observeWindowResize();
    this.observeScrollPosition();
    this.observeSideNav();
  }

  private observeWindowResize(): void {
    const resizeObservable$ = fromEvent(window, 'resize');
    this.subscription.add(resizeObservable$
      .pipe(
        debounceTime(100),
        filter(() => this.breakpointsService.isDesktop),
        delay(600)
      ).subscribe( () => {
        this.updateActionsAbsoluteBreakpoint();
        this.cdr.markForCheck();
      })
    );
  }

  private observeScrollPosition(): void {
    const resizeObservable$ = fromEvent(window, 'scroll');
    this.subscription.add(resizeObservable$
      .pipe(
        filter(() => this.breakpointsService.isDesktop && window.scrollY > 0 && !this.hideActions),
      ).subscribe( () => {
        const prev = this.actionsAbsolute;
        this.updateActionsPosition();
        if (prev !== this.actionsAbsolute) {
          this.cdr.markForCheck();
        }
      })
    );
  }

  private updateActionsAbsoluteBreakpoint(): void {
    const element = this.elementRef.nativeElement.firstElementChild.children.item(1);
    if (element) {
      const rect = this.elementRef.nativeElement.firstElementChild.children.item(1).getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      this.actionsAbsolutePositionInPx = rect.bottom - bodyRect.bottom + 64;
    }
  }

  private updateActionsPosition(): void {
    this.actionsAbsolute = window.scrollY > this.actionsAbsolutePositionInPx;
  }

  static isBlueLetterBibleAvailable(book: BibliaInfoBook): boolean {
    return !BibleContentComponent.blacklistBlueLetterBooks.includes(book);
  }

  protected onMouseEnter(verse: string): void {
    this.activeVerseIndex = verse;
  }

  protected onMouseLeave(): void {
    this.activeVerseIndex = null;
  }

  protected openBottomSheet(verse: string, verseNo: number, compare = false): void {
    const favouriteChapter = this.getFavouriteChapter(verseNo, compare);

    this.bottomSheet.open(BibleContentBottomSheetComponent, {
      data: {
        item: this.chapterItem,
        verseNo,
        isInStorage: this.findFavouriteChapter(favouriteChapter),
        isBlueLetterBibleAvailable: BibleContentComponent.isBlueLetterBibleAvailable(this.chapterItem.book.abbreviation as BibliaInfoBook),
      }
    }).afterDismissed().subscribe((result: BibleContentBottomSheetResult) => {
      switch (result?.type) {
        case BibleContentBottomSheetResultType.COMPARE_VERSE: this.compareVerse$.emit(favouriteChapter); break;
        case BibleContentBottomSheetResultType.COPY_VERSE: this.copyVerse(verse); break;
        case BibleContentBottomSheetResultType.ADD_VERSE: this.addVerse(verseNo, compare); break;
        case BibleContentBottomSheetResultType.REMOVE_VERSE: this.removeVerse(verseNo, compare); break;
      }
    });
  }

  protected copyVerses(): void {
    this.dialog.open<CopyVersesDialogComponent, CopyVersesDialogData, CopyVersesDialogResult>(CopyVersesDialogComponent, {
      data: {
        book: this.chapterItem.book,
        chapter: this.chapterItem.chapter,
        max: this.chapterItem.verses.length,
      },
      panelClass: 'reading'
    }).afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }
        const { from, to, keepVerses } = result;
        if (from > 0) {
          if (!keepVerses || from === to) {
            this.clipboard.copy(BibleComponent.getChapterStr(this.chapterItem.verses.slice(from - 1, to)));
          } else {
            this.clipboard.copy(BibleComponent.getChapterStrWithNumber(this.chapterItem.verses.slice(from - 1, to)));
          }
          this.snackbarService.open(`Skopiowano wersety ${from} - ${to}`, SnackBarType.COPY, 2000);
        }
    });
  }

  protected changeFontSize(fontSize: number): void {
    this.fontSize = fontSize;
  }

  protected fullScreenMode(value: boolean): void {
    this.fullScreen = value;
    this.fullScreen$.emit(value);

    if (!this.breakpointsService.isDesktop) {
      return;
    }
    this.hideActions = true;
    setTimeout(() => {
      this.updateActionsAbsoluteBreakpoint();
      this.updateActionsPosition();
      this.hideActions = false;
      this.cdr.markForCheck();
    }, 600);
  }

  private copyVerse(verse: string): void {
    this.clipboard.copy(verse);
    this.snackbarService.open('Skopiowano werset', SnackBarType.COPY, 2000);
  }

  private addVerse(verseNo: number, compare = false): void {
    this.leftPanelService.setFavouriteChapter(this.getFavouriteChapter(verseNo, compare));
    this.cdr.markForCheck();
  }

  private removeVerse(verseNo: number, compare = false): void {
    this.leftPanelService.removeFavouriteChapter(this.getFavouriteChapter(verseNo, compare));
    this.cdr.markForCheck();
  }

  private findFavouriteChapter(compare: FavouriteChapter): boolean {
    return !!this.leftPanelService.favouriteChapters.find(value => {
      return compare.bible === value.bible
        && compare.book === value.book
        && compare.chapter === value.chapter
        && compare.verse === value.verse
    })
  }

  private getFavouriteChapter(verseNo: number, compare = false): FavouriteChapter {
    return {
      bible: compare ? [...this.compareItems.values()][0].bible.abbreviation as BibliaInfoCode : this.chapterItem.bible.abbreviation as BibliaInfoCode,
      book: this.chapterItem.book.abbreviation as BibliaInfoBook,
      chapter: this.chapterItem.chapter,
      verse: verseNo,
    };
  }

  protected openTranslationInfoDialog(): void {
    this.dialog.open<TranslationInfoDialogComponent, TranslationInfoData>(TranslationInfoDialogComponent, {
      data: {
        chapterItem: this.chapterItem,
      },
      panelClass: 'reading'
    })
  }

  protected getNumber(number: number | number[]): void {
    if (this.isDialogLoading || !number) {
      return;
    }

    if (Array.isArray(number)) {
      this.openSelectNumberDialog(number);
    } else {
      this.isDialogLoading = true;
      this.fetchNumber(number);
    }
  }

  private openSelectNumberDialog(numbers: number[]): void {
    this.dialog.open<SelectNumberDialogComponent, number[]>(SelectNumberDialogComponent, { data: numbers }).afterClosed().subscribe(number => {
      if (number) {
        this.isDialogLoading = true;
        this.fetchNumber(number);
      }
    });
  }

  private fetchNumber(number): void {
    this.strongService.getNumber(number).pipe(
      catchError((errorRes: HttpErrorResponse) => {
        this.snackbarService.open(errorRes.error.message, SnackBarType.ERROR, 2000);
        return of(null);
      })
    ).subscribe(strong => {
      this.openConcordanceDialog(strong);
    });
  }

  private openConcordanceDialog(strong: Strong): void {
    if (!strong) {
      this.isDialogLoading = false;
      this.cdr.markForCheck();
      return;
    }
    this.dialog.open<ConcordanceDialogComponent, Strong>(ConcordanceDialogComponent, {
      data: strong, panelClass: ['reading', 'full', 'concordance']
    }).afterOpened().subscribe(() => {
      this.isDialogLoading = false;
      this.cdr.markForCheck();
    });
  }

  protected setVerseInNewLine(value: boolean): void {
    this.versesInNewLine = value;

    if (!this.breakpointsService.isDesktop) {
      return;
    }
    setTimeout(() => {
      this.updateActionsAbsoluteBreakpoint();
      this.cdr.markForCheck();
    });
  }

  protected chapterChange(result: ChapterChangeResult): void {
    this.chapterChange$.emit(result);
  }

  private observeSideNav(): void {
    this.subscription.add(this.menuService.toggle$.pipe(
      tap(() => {
        this.hideActions = true;
        this.cdr.markForCheck();
      }),
      delay(400),
    ).subscribe(() => {
      this.navigationOpened.update(value => !value);
      this.hideActions = false;
    }));
  }
}
